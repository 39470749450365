import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import { Pagination, PaginationItemType, Link, Button } from "@nextui-org/react";

var _ = require('lodash');

export default function App(params) {
    let asPath = params.asPath;
    if (!asPath.includes('page')) {
        asPath += asPath.includes('?') ? '&' : '?' + 'page=1';
    }

    const renderItem = ({ ref, key, value, isActive, onNext, onPrevious, setPage, className }) => {
        if (value === PaginationItemType.NEXT) {
            let nextPage = params.pageData.currentPage === params.pageData.pageCount ? params.pageData.pageCount : params.pageData.currentPage + 1;
            return (
                !(params.pageData.currentPage === params.pageData.pageCount) &&
                <>
                    <Link
                        // isDisabled={params.pageData.currentPage === params.pageData.pageCount}
                        key={key}
                        href={_.replace(asPath, /page=\d+/, `page=${nextPage}`)}
                        className="flex justify-center text-black hover:text-white rounded-[0.5rem] border-1 bg-none px-0 min-w-unit-10 h-unit-9 bg-white hover:bg-primary font-bold"
                        onClick={onNext}>
                        <FaChevronRight/>
                    </Link>
                </>);
        }

        if (value === PaginationItemType.PREV) {
            let prevPage = params.pageData.currentPage - 1 || 1;
            return (
                !(params.pageData.currentPage === 1) &&
                <>
                    <Link
                        // isDisabled={params.pageData.currentPage === params.pageData.pageCount}
                        key={key}
                        href={_.replace(asPath, /page=\d+/, `page=${prevPage}`)}
                        className="flex justify-center text-black hover:text-white rounded-[0.5rem] border-1 bg-none px-0 min-w-unit-10 h-unit-9 bg-white hover:bg-primary font-bold"
                        onClick={onPrevious}>
                        <FaChevronLeft/>
                    </Link>
                </>);
        }

        if (value === PaginationItemType.DOTS) {
            return <button key={key} className={className}>...</button>;
        }

        // cursor is the default item
        return (
            <Link className={`${isActive ? 'bg-primary text-white' : 'bg-white'} border-1 font-bold rounded-[0.5rem]`}
                  href={_.replace(asPath, /page=\d+/, `page=${value}`)}
            >
                <button
                    key={key}
                    ref={ref}
                    className={_.concat(
                        className,
                        `${isActive ? 'bg-primary text-white' : 'bg-white'} hover:bg-primary hover:text-white font-bold rounded-[0.5rem]`
                    )}
                    onClick={() => setPage(value)}
                >
                    {value}
                </button>
            </Link>
        );
    };

    return (
        <Pagination
            showControls
            radius="full"
            variant="light"
            initialPage={1}
            className="gap-2"
            disableCursorAnimation
            renderItem={renderItem}
            total={params.pageData.pageCount}
            page={params.pageData.currentPage}
        />
    );
}
